var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "componentBox" },
    [
      _c(
        "div",
        {
          staticClass: "interactiveBox",
          style: { opacity: _vm.showInputBox ? 1 : 0 }
        },
        [
          _c(
            "div",
            {
              staticClass: "questionOuterBox",
              style: { marginBottom: `-${_vm.displacement}px` },
              attrs: { title: _vm.questionItem.question }
            },
            [
              _c(
                "div",
                {
                  staticClass: "questionIconBox",
                  style: { height: `${_vm.questionBoxHeight}px` }
                },
                [
                  _c("transition", { attrs: { name: "transitionStyle" } }, [
                    _vm.inputIsGetFocus
                      ? _c(
                          "div",
                          {
                            staticClass: "questionIcon",
                            style: { height: `${_vm.questionIconHeight}px` }
                          },
                          [
                            _c("el-image", {
                              staticStyle: { width: "15px", height: "15px" },
                              attrs: {
                                src: require("../../../assets/images/newLogin/questionIconSelectedStyle.png"),
                                fit: "fill"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]),
                  _c("transition", { attrs: { name: "transitionStyle" } }, [
                    !_vm.inputIsGetFocus
                      ? _c(
                          "div",
                          {
                            staticClass: "questionIcon",
                            style: { height: `${_vm.questionIconHeight}px` }
                          },
                          [
                            _c("el-image", {
                              staticStyle: { width: "15px", height: "15px" },
                              attrs: {
                                src: require("../../../assets/images/newLogin/questionIcon.png"),
                                fit: "fill"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ],
                1
              ),
              _c(
                "div",
                {
                  ref: "questionBox",
                  staticClass: "questionBox",
                  style: { color: _vm.inputIsGetFocus ? "#33CC66" : "#8F8F8F" }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.questionItem.question) +
                      "\n      "
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "operatingArea",
                  style: { height: `${_vm.questionBoxHeight}px` }
                },
                [
                  _vm.needCloseQuestion
                    ? _c("span", {
                        staticClass: "iconfont_Me icon-x iconStyle",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.closeQuestionBox.apply(null, arguments)
                          }
                        }
                      })
                    : _vm._e()
                ]
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass: "borderBox",
              style: { height: `${_vm.borderBoxHeight}px` }
            },
            [
              _c(
                "div",
                {
                  staticClass: "answerBox",
                  style: { marginTop: `${_vm.displacement + 6}px` }
                },
                [
                  _c(
                    "div",
                    { staticClass: "answerIcon" },
                    [
                      _c("el-image", {
                        staticStyle: { width: "18px", height: "18px" },
                        attrs: {
                          src: require("../../../assets/images/newLogin/answerIcon.png"),
                          fit: "fill"
                        }
                      })
                    ],
                    1
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.inputText,
                        expression: "inputText"
                      }
                    ],
                    ref: "answerInput",
                    staticClass: "answerInput",
                    attrs: { type: "text" },
                    domProps: { value: _vm.inputText },
                    on: {
                      focus: function($event) {
                        $event.stopPropagation()
                        return _vm.inputGetFocus.apply(null, arguments)
                      },
                      blur: function($event) {
                        $event.stopPropagation()
                        return _vm.inputLosesFocus.apply(null, arguments)
                      },
                      input: [
                        function($event) {
                          if ($event.target.composing) return
                          _vm.inputText = $event.target.value
                        },
                        function($event) {
                          $event.stopPropagation()
                          return _vm.inputContentChange.apply(null, arguments)
                        }
                      ]
                    }
                  })
                ]
              )
            ]
          )
        ]
      ),
      _c("transition", { attrs: { name: "defaultBoxStyle" } }, [
        !_vm.showInputBox
          ? _c(
              "div",
              {
                staticClass: "defaultBox",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.startInput.apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "questionIcon" },
                  [
                    _c("el-image", {
                      staticStyle: { width: "18px", height: "18px" },
                      attrs: {
                        src: require("../../../assets/images/newLogin/questionIconLarge.png"),
                        fit: "fill"
                      }
                    })
                  ],
                  1
                ),
                _c("div", { staticClass: "questionText" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.questionItem.question) +
                      "\n      "
                  )
                ])
              ]
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }