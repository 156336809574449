<template>
  <div class="componentBox">
    <div class="interactiveBox" :style="{ opacity: showInputBox ? 1 : 0 }">
      <div
        class="questionOuterBox"
        :style="{ marginBottom: `-${displacement}px` }"
        :title="questionItem.question"
      >
        <div
          class="questionIconBox"
          :style="{ height: `${questionBoxHeight}px` }"
        >
          <transition name="transitionStyle">
            <div
              class="questionIcon"
              :style="{ height: `${questionIconHeight}px` }"
              v-if="inputIsGetFocus"
            >
              <el-image
                style="width: 15px; height: 15px"
                :src="
                  require('../../../assets/images/newLogin/questionIconSelectedStyle.png')
                "
                fit="fill"
              ></el-image>
            </div>
          </transition>
          <transition name="transitionStyle">
            <div
              class="questionIcon"
              :style="{ height: `${questionIconHeight}px` }"
              v-if="!inputIsGetFocus"
            >
              <el-image
                style="width: 15px; height: 15px"
                :src="
                  require('../../../assets/images/newLogin/questionIcon.png')
                "
                fit="fill"
              ></el-image>
            </div>
          </transition>
        </div>
        <div
          ref="questionBox"
          class="questionBox"
          :style="{ color: inputIsGetFocus ? '#33CC66' : '#8F8F8F' }"
        >
          {{ questionItem.question }}
        </div>
        <div
          class="operatingArea"
          :style="{ height: `${questionBoxHeight}px` }"
        >
          <span
            v-if="needCloseQuestion"
            class="iconfont_Me icon-x iconStyle"
            @click.stop="closeQuestionBox"
          ></span>
        </div>
      </div>
      <div class="borderBox" :style="{ height: `${borderBoxHeight}px` }">
        <div class="answerBox" :style="{ marginTop: `${displacement + 6}px` }">
          <div class="answerIcon">
            <el-image
              style="width: 18px; height: 18px"
              :src="require('../../../assets/images/newLogin/answerIcon.png')"
              fit="fill"
            ></el-image>
          </div>
          <input
            type="text"
            class="answerInput"
            ref="answerInput"
            v-model="inputText"
            @focus.stop="inputGetFocus"
            @blur.stop="inputLosesFocus"
            @input.stop="inputContentChange"
          />
        </div>
      </div>
    </div>
    <transition name="defaultBoxStyle">
      <div class="defaultBox" v-if="!showInputBox" @click.stop="startInput">
        <div class="questionIcon">
          <el-image
            style="width: 18px; height: 18px"
            :src="
              require('../../../assets/images/newLogin/questionIconLarge.png')
            "
            fit="fill"
          ></el-image>
        </div>
        <div class="questionText">
          {{ questionItem.question }}
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  props: {
    // 问题文本
    questionItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
    // 需要关闭问题的按钮
    needCloseQuestion: {
      type: Boolean,
      default: false,
    },
    // 自动获取焦点
    autoFocus: {
      type: Boolean,
      default: false,
    },
    // 在父组件所属的下标，用于回调方便查找
    indexInParent: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      // 问题盒子下移的位置
      displacement: 0,
      // 问题盒子高度
      questionBoxHeight: 0,
      // 问题图标以及操作区域盒子高度
      questionIconHeight: 0,
      // 边框盒子高度
      borderBoxHeight: 0,
      // 问题文本行数
      questionLineNumber: 0,
      // 输入框获取到焦点
      inputIsGetFocus: false,
      // 输入框文本
      inputText: "",
      // 显示输入框(默认关闭，点击后显示)
      showInputBox: false,
      // 失去焦点定时器
      timer: null,
    };
  },
  watch: {
    // 监听是否设置了自动获取焦点
    autoFocus: {
      handler(val, old) {
        if (val && val !== old) {
          this.$nextTick(() => {
            this.showInputBox = true;
          });
        }
      },
      deep: true,
      immediate: true,
    },
    // 监听输入框显示
    showInputBox: {
      handler(val, old) {
        if (val && val !== old) {
          this.$nextTick(() => {
            // 默认强制获取焦点
            this.$refs.answerInput.focus();
          });
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.$nextTick(() => {
      // 获取问题文本区域的高度
      this.questionBoxHeight = this.$refs.questionBox.offsetHeight;
      this.questionLineNumber = Math.round(this.questionBoxHeight / 18);
      this.displacement = this.questionBoxHeight / 2;
      if (this.questionLineNumber === 2) {
        this.borderBoxHeight = 50 + this.displacement / 2;
        this.questionIconHeight = 18;
      } else {
        this.borderBoxHeight = 50;
        this.questionIconHeight = this.questionBoxHeight;
      }
    });
  },
  destroyed() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },
  methods: {
    // 开始输入
    startInput() {
      this.showInputBox = true;
    },
    // 点击关闭问题
    closeQuestionBox() {
      this.$emit("closeQuestionBox", this.indexInParent);
    },
    // 输入框获取到焦点
    inputGetFocus() {
      this.inputIsGetFocus = true;
    },
    // 输入框失去焦点
    inputLosesFocus() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.inputIsGetFocus = false;
        this.$nextTick(() => {
          if (this.inputText === "") {
            this.showInputBox = false;
          }
        });
      }, 200);
    },
    // 输入框内容改变触发
    inputContentChange() {
      let answerItem = {
        answer: this.inputText,
        providerId: this.$store.state.accountStore.secureInfo.providerId,
        question: this.questionItem.question,
        questionId: this.questionItem.questionId,
      };
      this.$emit("getAnswer", answerItem, this.indexInParent);
    },
  },
};
</script>
<style lang="stylus" scoped>
.defaultBoxStyle-enter-active, .defaultBoxStyle-leave-active
  transition: opacity .3s;
.defaultBoxStyle-enter,.defaultBoxStyle-leave-to
  opacity: 0;
.transitionStyle-enter-active, .transitionStyle-leave-active
  transition: opacity .3s;
.transitionStyle-enter,.transitionStyle-leave-to
  opacity: 0;
.componentBox
  width 100%;
  min-height 0;
  position relative;
  .interactiveBox
    z-index 1;
    width 100%;
    overflow hidden;
    transition opacity .3s;
    position relative;
    .questionOuterBox
      min-width: 0;
      background transparent;
      box-sizing border-box;
      padding 0 25px 0 11px;
      overflow hidden;
      position relative;
      z-index 2;
      display flex;
      align-items center;
      justify-content flex-start;
      .questionIconBox
        width 30px;
        flex-shrink 0;
        background #FFFFFF;
        position relative;
        .questionIcon
          top 0;
          left 0;
          width 100%;
          display flex;
          position absolute;
          align-items center;
          justify-content center;
      .questionBox
        min-width 0;
        line-height 18px;
        background #FFFFFF;
        overflow: hidden;
        font-size 12px;
        cursor pointer;
        transition color .3s;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        user-select none;
      .operatingArea
        width 40px;
        flex-shrink 0;
        background #FFFFFF;
        display flex;
        align-items center;
        justify-content center;
        .iconStyle
          font-size 12px;
          color #CCCCCC;
          cursor pointer;
    .borderBox
      width 100%;
      height 50px;
      border-radius 4px;
      z-index 1;
      position relative;
      box-sizing border-box;
      padding 0 18px;
      border 1px solid #8F8F8F;
      .answerBox
        width 100%;
        height 18px;
        display flex;
        align-items center;
        justify-content flex-start;
        .answerIcon
          width 18px;
          height 18px;
        .answerInput
          width 100%;
          flex 1;
          caret-color: #33CC66;
          padding-left 15px;
          font-size 14px;
          color #343434;
          outline none;
          border none;
  .defaultBox
    height 50px;
    width 100%;
    cursor text;
    display flex;
    z-index 2;
    position absolute;
    left 0;
    bottom 0;
    padding 0 18px;
    border-radius 4px;
    align-items center;
    box-sizing border-box;
    border 1px solid #8F8F8F;
    justify-content flex-start;
    .questionIcon
      width 18px;
      height 18px;
      flex-shrink 0;
    .questionText
      flex 1;
      height 18px;
      box-sizing border-box;
      padding-left 10px;
      overflow: hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
      font-size 14px;
      color #CCCCCC;
      user-select none;
</style>
